<template>
  <div style="overflowY: auto; height: 100%; width: 100%">
    <!-- Using occurrence id as key to let vue handles the update process -->
    <occurrence-updater
      v-if="currentMode === mode.edit"
      :key="occurrenceId"
      :occurrence-id="occurrenceId"
      @onGoBackButtonClicked="onGoBackButtonClicked"
      @canceled="onEditCanceled"
      @edited="onOccurrenceEdited"
    />
    <occurrence-viewer
      v-else
      :key="occurrenceId"
      :occurrence-id="occurrenceId"
      @onEditButtonClicked="onEditButtonClicked"
      @onGoBackButtonClicked="onGoBackButtonClicked"
    />
    <v-snackbar
      v-model="enableAlert"
      data-cy="snackbar"
      color="primary"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="enableAlert = false"
        >
          <v-icon color="white">
            {{ icons.close }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiClose } from '@mdi/js';
import OccurrenceUpdater from './OccurrenceUpdater.vue';
import OccurrenceViewer from './OccurrenceViewer.vue';

export default {
  name: 'Occurrence',
  components: {
    OccurrenceUpdater,
    OccurrenceViewer,
  },
  data() {
    return {
      mode: {
        edit: 0,
        view: 1,
      },
      currentMode: 0,
      alertMessage: '',
      enableAlert: false,
      fromRoute: undefined,
      occurrenceId: undefined,
      icons: { close: mdiClose },
    };
  },
  watch: {
    occurrenceId() {
      if (this.occurrenceId) {
        this.currentMode = this.mode.view;
      }
    },
  },
  created() {
    this.occurrenceId = this.$route.params?.occurrenceId;
  },
  methods: {
    onEditButtonClicked() {
      this.currentMode = this.mode.edit;
    },
    goBack() {
      if (this.fromRoute?.name) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'faceid-occurrences' });
      }
    },
    onGoBackButtonClicked() {
      if (this.currentMode === this.mode.edit) {
        this.currentMode = this.mode.view;
      } else {
        this.goBack();
      }
    },
    onEditCanceled() {
      this.currentMode = this.mode.view;
    },
    onOccurrenceEdited() {
      this.currentMode = this.mode.view;
      this.enableAlert = true;
      this.alertMessage = this.$t('deconve.occurrenceEdited');
    },
  },
  beforeRouteEnter(to, from, next) {
    // Saves the fromRoute info. The component public data is accessed by the `vm` object
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.fromRoute = from;
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.occurrenceId = this.$route.params?.occurrenceId;
  },
};
</script>
