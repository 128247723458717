<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '640px'"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-8 py-4"
    >
      <v-card-title class="pl-2 h6 text-sm-h5 font-weight-bold textBlack--text">
        {{ $t('deconve.selectNotifications') }}
      </v-card-title>

      <v-data-iterator
        class="d-flex flex-column mx-2 mt-3"
        style="height: 100%"
        :items="notifications.items"
        :loading="isLoading"
        :server-items-length="notifications.total"
        hide-default-footer
        @update:page="onPageNumberUpdated"
      >
        <template v-slot:header>
          <div
            class="pb-4 d-flex align-center"
            style="border-bottom: 1px solid;"
            :style="{
              borderColor: $vuetify.theme.themes.light.border,
            }"
          >
            <input-text
              v-model="search"
              max-width="440px"
              left-icon="mdi-magnify"
              :placeholder="$t('deconve.search')"
            />

            <date-time-range-picker
              class="mx-2"
              hide-details
              :start-date-time="dateOptions.createdAfter"
              :end-date-time="dateOptions.createdBefore"
              :mode="dateOptions.createdDateOption"
              @change="onCreatedAtDateChanged"
            />
          </div>
        </template>

        <template v-slot:loading>
          <span class="ml-1 pa-2">
            {{ $t('deconve.loading') }}
          </span>
        </template>

        <template v-slot:no-data>
          <span
            class="ml-1 pa-2 neutralPrimary--text
        text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
          >
            {{ $t('deconve.noNotification') }}
          </span>
        </template>

        <template v-slot:default="props">
          <div
            style="flex: 1; overflow-y: auto;"
            :style="{
              backgroundColor: $vuetify.theme.themes.light.background,
            }"
          >
            <v-progress-linear
              v-if="isLoading"
              :indeterminate="true"
            />
            <div class="d-flex flex-wrap pa-2">
              <v-col
                v-for="item in props.items"
                :key="item.id"
                class="pa-1"
                style="height: fit-content"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <notification-preview
                  :id="item.id"
                  :key="item.id"
                  :data="item"
                  enable-select-notification
                  :is-selected="isNotificationSelected(item.id)"
                  @onSelectButtonClicked="onNotificationSelectButtonClicked"
                />
              </v-col>
            </div>
          </div>
        </template>
        <template v-slot:footer="props">
          <footer-data-iterator
            :items-per-page-array="itemsPerPageArray"
            :items-per-page="itemsPerPage"
            :page="page"
            :total-data="props.pagination.itemsLength"
            @changeItensPerPage="updateItemsPerPage"
            @changePage="onPageNumberUpdated"
          />
        </template>
      </v-data-iterator>
      <v-alert
        border="left"
        type="warning"
        :icon="false"
        outlined
        text
      >
        <v-row align="center">
          <v-col
            cols="1"
          >
            <v-checkbox v-model="warningCheckbox" />
          </v-col>
          <v-col
            cols="11"
            style="padding-left: 20px"
          >
            {{ $t('deconve.selectNotificationsAlert') }}
          </v-col>
        </v-row>
      </v-alert>
      <v-card-actions
        class="mx-2 pr-0"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none pa-3"
          color="neutral"
          @click="cancel"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!warningCheckbox"
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none pa-3"
          color="primary"
          @click="confirm"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions } from 'vuex';
import moment from 'moment';

import { DATE_TIME_OPTIONS } from '@/utils/dateTimeRange';
import DateTimeRangePicker from './DateTimeRangePicker.vue';
import FooterDataIterator from './FooterDataIterator.vue';
import InputText from './InputText.vue';
import NotificationPreview from './NotificationPreview.vue';

export default {
  name: 'NotificationSelectionDialog',
  components: {
    DateTimeRangePicker,
    FooterDataIterator,
    NotificationPreview,
    InputText,
  },
  props: {
    // TODO: Use this to filter notifications by workspace
    workspaceId: { type: String, default: undefined },
    isOnDeconveHub: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    itemsPerPageArray: [4, 8, 12],
    search: undefined,
    page: 1,
    itemsPerPage: 4,
    dateOptions: {},
    dialog: false,
    resolve: null,
    reject: null,
    notifications: [],
    unitId: null,
    selectedNotifications: [],
    warningCheckbox: false,
  }),
  watch: {
    search() {
      this.fetchNotifications();
    },
  },
  methods: {
    ...mapActions({
      fetchUnitNotifications: 'faceid/fetchUnitNotifications',
    }),
    open(unitId, referenceDate, selectedNotifications) {
      this.selectedNotifications = [...selectedNotifications];
      this.unitId = unitId;
      this.referenceDate = referenceDate;
      this.dateOptions = this.setDateOptions();

      this.fetchNotifications();
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    setDateOptions() {
      const referenceDate = this.referenceDate ? moment(this.referenceDate) : moment();
      const createdBefore = moment(referenceDate).add(30, 'minutes').toISOString();
      const createdAfter = moment(referenceDate).subtract(30, 'minutes').toISOString();

      return {
        createdBefore,
        createdAfter,
        createdDateOption: DATE_TIME_OPTIONS.custom,
      };
    },
    onCreatedAtDateChanged({ startDate, endDate, mode }) {
      this.dateOptions.createdBefore = endDate;
      this.dateOptions.createdAfter = startDate;
      this.dateOptions.createdDateOption = mode;

      this.fetchNotifications();
    },
    isNotificationSelected(notificationId) {
      const index = this.selectedNotifications.findIndex(({ id }) => id === notificationId);

      return index >= 0;
    },
    removeNotification(notificationId) {
      const index = this.selectedNotifications.findIndex(({ id }) => id === notificationId);

      if (index >= 0) {
        this.selectedNotifications.splice(index, 1);
      }
    },
    addNotification(notificationId) {
      // To avoid duplicated notificationId
      this.removeNotification(notificationId);

      const { items: notifications } = this.notifications;
      const notification = notifications.find(({ id }) => id === notificationId);

      if (notification) {
        this.selectedNotifications.push(notification);
      }
    },
    onNotificationSelectButtonClicked({ notificationId, isSelected }) {
      if (isSelected) {
        this.addNotification(notificationId);
      } else {
        this.removeNotification(notificationId);
      }
    },
    fetchNotifications() {
      this.isLoading = true;
      const { createdAfter, createdBefore } = this.dateOptions;

      const options = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        search: this.search,
        createdAfter,
        createdBefore,
        unitId: this.unitId,
      };

      this.fetchUnitNotifications(options).then((notifications) => {
        this.notifications = notifications;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.fetchNotifications();
    },
    onPageNumberUpdated(page) {
      this.page = page;
      this.fetchNotifications();
    },
    confirm() {
      this.dialog = false;
      this.resolve(this.selectedNotifications);
      this.reset();
    },
    reset() {
      this.isLoading = false;
      this.search = undefined;
      this.page = 1;
      this.itemsPerPage = 4;
      this.notifications = [];
      this.unitId = null;
      this.selectedNotifications = [];
      this.warningCheckbox = false;
    },
    cancel() {
      this.dialog = false;
      this.reset();
      this.reject();
    },
  },
};
</script>
