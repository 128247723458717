// Copyright (C) 2024 Deconve Technology. All rights reserved.

// eslint-disable-next-line import/prefer-default-export
export const OCCURRENCE_THEFT_STATUS = {
  stole: 'stole',
  triedToSteal: 'tried_to_steal',
  didNotTryToSteal: 'did_not_try_to_steal',
};

export const OCCURRENCE_TIME_LINE = {
  personIdentified: 'person_identified',
  identityConfirmed: 'tried_to_steal',
  following: 'without_alteration',
  leftStore: 'left_store',
  finished: 'finished',
};
